<template>
  <div>
    <!-- <Navbar />
    <Sidebar /> -->
    <section
      class="main"
    >
      <div style="width: 100%; height: 850px; overflow-y: scroll;">
        <ul style="padding: 0; ">
          <li
            v-for="item in getTeamsNames"
            :key="item.teamNameId"
            class="pm-li"
          >
            <div style="display: flex; justify-content: space-between; align-items: center">
              <h5>{{ $t('TeamName') }}: {{ item.teamName }}</h5>
              <button
                class="btn btn-outline-primary"
                type="button"
                @click="getTeam(item.teamNameId)"
              >
                {{ showHide == true && activeId == item.teamNameId ? $t('Hide') : $t('Show') }} {{ $t('Articles') }}
              </button>
            </div>
            <ul
              class="ul-order"
              style="z-index: -1"
            >
              <li
                v-for="itemd in getProductsInStock"
                v-show="showHide == true && item.teamNameId == activeId "
                :key="itemd.id"
              >

                <div
                  class="order-card"
                  style="display: flex; align-items: center"
                >
                  <!-- <router-link
                    to="/product-in-stock-track-trace"
                    style="color:black; text-decoration: none;"
                  > -->
                  <p
                    v-b-tooltip.hover.bottom
                    v-b-toggle.productinstok-tracking-modal
                    style="width: 20%"
                    :title="$t('ClickHere')"
                    @click="fetchTrackAndTrace(itemd.productInStockId)"
                  >
                    {{ $t('ArticleN') }}: <br>
                    <button
                      style="background:#FF274F;color: rgb(244, 241, 237); border-color: transparent; padding: 6px;border-radius: 5px;"
                    >{{ itemd.articleNumber }}
                    </button>
                  </p>
                  <!-- </router-link> -->
                  <p style="width: 20%">
                    {{ $t('State') }}: <br>
                    {{ $t(itemd.state) }}
                  </p>
                  <p style="width: 20%">
                    {{ $t('Created') }}: <br>{{ dateModified(itemd.created) }}
                  </p>
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    @click="
                      productsWithStock(item.teamNameId, itemd.productInStockId);
                      getArticleAndTeamID(item.teamNameId, itemd.productInStockId)
                    "
                  >
                    {{ showO == true && activeOrderStatus == itemd.productInStockId? $t('Hide') : $t('Show') }} {{ $t('Items') }}
                  </button>
                </div>
                <!-- v-if="showO && activeOrderStatus == itemd.orderNumber" -->
                <ul>
                  <li
                    v-for="itemz in getProductInStockWithStates"
                    v-show="itemd.productInStockId === activeOrderStatus"
                    :key="itemz.id"
                  >
                    <span style="margin-right: 40px">
                      {{ itemz.articleNumber }}</span>
                    <button
                      class="btn btn-outline-primary"
                      type="button"
                      @click=" productInStockMaterialVariants(itemd.articleNumber);"
                    >
                      <!-- showMaterials(itemd.orderNumber, itemz.orderItemId); -->
                      {{ showO == true && showM == true ? $t('Hide') : $t('Show') }} {{ $t('MaterialVariants') }}
                    </button>
                    <!-- <br> -->
                    <button
                      v-if="itemz.processingUser == ''"

                      v-b-modal.modal-assign-employee-store
                      style="margin-left: 5px;"
                      class="btn btn-outline-primary"
                      type="button"
                      @click="loadEmployees(itemz.productInStockId)"
                    >
                      {{ $t('AssignEmployee') }}
                    </button>
                    <div
                      v-if="itemz.processingUser != ''"
                      style="display: inline-flex; align-items: baseline"
                    >
                      <span style="margin-left: 20px;">
                        {{ $t('CurrentEmployee') }}:</span>
                      <b-input
                        v-if="editEmployee == false"
                        v-model="itemz.processingUser"
                        type="text"
                        disabled
                        style="margin-left: 10px;"
                      />
                      <vue-select
                        v-if="editEmployee == true"
                        v-model="employeeId"
                        :options="getTeamOverview.users"
                        :placeholder="$t('SelectEmployee')"
                        :reduce="(e) => e.userId"
                        label="name"
                        aria-describedby="input-1-live-feedback"
                        style="margin-left: 10px; min-width: 220px;"
                      />
                      <div
                        class="inside-actions"
                        @click="editEmployee = !editEmployee"
                      >
                        <b-icon-pencil v-if="editEmployee == false" />
                        <b-icon-check2
                          v-if="editEmployee == true"
                          @click="editProcessingUser(itemz.productInStockId)"
                        />
                      </div>
                    </div>
                    <div>
                      <div style="margin-right: 10px; margin-top: 20px">
                        <p>{{ $t('ItemState') }}:</p>
                        <div
                          v-if="stateType === 'Medium'"
                          style="display: flex; flex-wrap: wrap"
                        >
                          <div
                            v-for="(stage, idx) in itemz.stateDtos"
                            :key="idx"
                            :class="{
                              failed: itemz.stateDtos[11].stateProcessed == true, active: itemz.stateDtos[idx].stateProcessed == true && itemz.stateDtos[11].stateProcessed == false, activee: itemz.stateDtos[idx].stateProcessed == true && idx != itemz.state,
                            }"
                            class="stage-card"
                            @click="changeSFjolla(stage.value, itemd.productInStockId, itemz.stateDtos[itemz.state].stateName, itemz.orderItemId, itemz.articleNumber)"
                          >
                            {{ stage.name == $t('QualityCheckNotPassed') ? `${stage.name} ${itemz.qualityCheckerCounter >0 ? `: ${itemz.qualityCheckerCounter}`:''} ` : stage.name }}
                          </div>
                        </div>
                        <div
                          v-if="stateType === 'High'"
                          style="display: flex; flex-wrap: wrap"
                        >
                          <div
                            v-for="(stage, idx) in itemz.stateDtos"
                            :key="idx"
                            v-b-tooltip.hover.bottom
                            :title="stage.stateName"
                            :class="{
                              failed: itemz.stateDtos[18].stateProcessed == true && itemz.stateDtos[19].stateProcessed == false, active: itemz.stateDtos[idx].stateProcessed == true && itemz.stateDtos[18].stateProcessed == false, activee: itemz.stateDtos[idx].stateProcessed == true && idx != itemz.state,
                            }"
                            class="stage-card"
                            @click="changeS(stage.stateName, itemd.productInStockId, itemz.stateDtos[itemz.state].stateName, itemz.productInStockId, itemz.articleNumber)"
                          >
                            <!-- disabled: comingStatus.newState === 'InPatternQualityNotPassed' && idx > itemz.state, -->
                            <img
                              class="iconat"
                              alt=""
                              :src="require(`../../assets/stock/Logo-${getLoggedInUser.role == 'TeamLeader'? idx+=1 : getLoggedInUser.role == 'Deliverer'? idx+=7 : idx+1}.svg`)"
                            >
                            <div class="first-div">
                              <div v-if="nState == 'InPatternQualityNotPassed'">
                                {{ stage.value == 'InPatternQualityNotPassed' ? `Failed Attempts ${itemz.qualityCheckerCounter >0 ? `: ${itemz.qualityCheckerCounter}`:''} ` : '' }}
                              </div>
                              <div v-if="nState == 'InSewingQualityNotPassed'">
                                {{ stage.value == 'InSewingQualityNotPassed' ? `Failed Attempts ${itemz.qualityCheckerCounter >0 ? `: ${itemz.qualityCheckerCounter}`:''} ` : '' }}
                              </div>
                              <div v-if="nState == 'InHandSewingQualityNotPassed'">
                                {{ stage.value == 'InHandSewingQualityNotPassed' ? `Failed Attempts ${itemz.qualityCheckerCounter >0 ? `: ${itemz.qualityCheckerCounter}`:''} ` : '' }}
                              </div>
                              <div v-else-if="qualityFailed.newState == 'QualityCheckNotPassed'">
                                {{ stage.name == $t('QualityCheckNotPassed') ? `Failed Attempts ${itemz.qualityCheckerCounter >0 ? `: ${itemz.qualityCheckerCounter}`:''} ` : '' }}
                              </div>
                            </div>
                            <div class="second-div">
                              <div v-if="comingStatus.newState == 'InPatternQualityNotPassed'">
                                {{ stage.value == 'InPatternQualityNotPassed' ? (
                                  stages.find(item => item.value === stage.stateName).name +
                                  (itemz.qualityCheckerCounter > 0 ? `: ${itemz.qualityCheckerCounter}` : '')
                                ) :
                                  stages.find(item => item.value === stage.stateName).name }}
                              </div>
                              <div v-if="comingStatus.newState == 'InSewingQualityNotPassed'">
                                {{ stage.value == 'InSewingQualityNotPassed' ? (
                                  stages.find(item => item.value === stage.stateName).name +
                                  (itemz.qualityCheckerCounter > 0 ? `: ${itemz.qualityCheckerCounter}` : '')
                                ) :
                                  stages.find(item => item.value === stage.stateName).name }}
                              </div>
                              <div v-if="comingStatus.newState == 'InHandSewingQualityNotPassed'">
                                {{ stage.value == 'InHandSewingQualityNotPassed' ? (
                                  stages.find(item => item.value === stage.stateName).name +
                                  (itemz.qualityCheckerCounter > 0 ? `: ${itemz.qualityCheckerCounter}` : '')
                                ) :
                                  stages.find(item => item.value === stage.stateName).name }}
                              </div>
                              <div v-else-if="qualityFailed.newState == 'QualityCheckNotPassed'">
                                {{ stage.name == $t('QualityCheckNotPassed') ? (
                                  stages.find(item => item.value === stage.stateName).name +
                                  (itemz.qualityCheckerCounter > 0 ? `: ${itemz.qualityCheckerCounter}` : '')
                                ) :
                                  stages.find(item => item.value === stage.stateName).name }}
                              </div>
                              <div v-else>
                                {{
                                  stage.stateName == true ?
                                    (
                                      stages.find(item => item.value === stage.stateName).name +
                                      (itemz.qualityCheckerCounter > 0 ? `: ${itemz.qualityCheckerCounter}` : '')
                                    ) :
                                    stages.find(item => item.value === stage.stateName).name
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="showM && das == itemz.articleNumber"
                    >
                      <div style="margin-right: 10px; margin-top: 20px">
                        <p style="font-weight: 600">
                          {{ $t('MaterialVariants') }}:
                        </p>
                        <div
                          class="material-con"
                          style="display: inline-flex; flex-wrap: wrap; justify-content: flex-start; width: 100%"
                        >
                          <div
                            v-for="material in getProductInStockMaterialVariants"
                            :key="material.materialVariantId"
                            class="material-info"
                            :class="{
                              failed2: material.isStockAvailable == false,
                              good: material.isStockAvailable == true
                            }"
                          >
                            <!-- <b-button> -->
                            <!-- Hover Me -->
                            <div style="margin-top:10px;display: flex;justify-content: space-between;">
                              <p style="color: #262E6C; font-weight: bold;">
                                {{ $t('Name') }}:</p> <p>{{ material.name }}</p>

                            </div>
                            <div class="hr" />
                            <div style="margin-top:10px;display: flex;justify-content: space-between;">
                              <p style="color: #262E6C; font-weight: bold;">
                                {{ $t('MeasurementType') }}:
                              </p> <p>{{ $t(material.measurementType) }}</p>
                            </div>
                            <div class="hr" />
                            <div style="margin-top:10px;display: flex; justify-content: space-between;">
                              <p style="color:#262E6C ; font-weight: bold;">
                                {{
                                  material.length == 0
                                    ? `${$t('Quantity')}: ${material.quantity === 1 ? material.quantity+` ${$t('Part')}` : material.quantity > 0 ? material.quantity+` ${$t('Parts')}` : ""}`
                                    : `${$t('Length')}: ${material.length === 1 ? material.length+` ${$t('Meter')}` : material.length > 0 ? material.length+` ${$t('Meters')}` : ""}`
                                }}
                              </p>
                            </div>
                            <!-- </b-button> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
    <div style="padding: 0px 30px 10px 110px">
      <b-pagination
        v-if="getTotalItemsForTeams > 15"
        v-model="page"
        style="border: none !important; padding:none !important"
        :total-rows="getTotalItemsForTeams"
        :per-page="pageSize"
        first-number
        last-number
        align="fill"
        prev-class="prev-itemi"
        next-class="next-itemi"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
        </template>
        <template #next-text>
          <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
        </template>
      </b-pagination>
    </div>
    <ChangeStatusInStoreModal
      :id="activeId"
      :c-status="comingStatus"
      :t-status="teamNameStatus"
      :l-status="locationStatus"
      :control-check-status="controlCheckStatus"
      :q-failed="changeModal"
      :article-number-title="articleNumberTitle"
      :order-failed="qualityFailed"
      :show-team="showTeamInModal"
      :show-location="showLocation"
      :show-control-check="showControlCheck"
      :n-state="nState"
      @changeOrderStatus="changeStage"
      @qualityChFailed="changeStage"
      @teamName="changeStage"
    />
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
    <AssignEmployeeModalInStore
      :item-id="itemId"
      @assignEmployee="assignProcessingEmployee"
    />
    <ArticleNameTrackingToggle
      @onCancelToggle="onCancelToggle"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// @ is an alias to /src
import ArticleNameTrackingToggle from '@/components/productsInStock/modals/ArticleNameTrackingToggle.vue'
import Sidebar from '@/components/sidebars/Sidebar.vue';
import Navbar from '@/components/Navbar.vue';
import ChangeStatusInStoreModal from '@/components/productsInStock/modals/ChangeStatusInStoreModal.vue'
import moment from 'moment'
import AssignEmployeeModalInStore from '../products/modals/AssignEmployeeModalInStore.vue'
import { client } from '../../domainConfig'

export default {
  name: 'ProductManagementInStore',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    ChangeStatusInStoreModal,
    AssignEmployeeModalInStore,
    ArticleNameTrackingToggle,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['teamIdFromUrl', 'pagee', 'pageSizee'],
  data() {
    return {
      isSidebarOpen: false,
      employeeId: null,
      editEmployee: false,
      articleNumber: '',
      articleNumberTitle: '',
      showHide: false,
      showM: false,
      showO: false,
      itemId: '',
      changeModal: false,
      showTeamInModal: false,
      showLocation: false,
      showControlCheck: false,
      client: client.clientName,
      stateType: client.stateType,
      rows: [],
      activeId: '',
      orderStatus: '',
      activeOrderStatus: '',
      orders: [],
      page: 1,
      pageSize: 15,
      productInStockId: null,
      teamNameIdddd: null,
      oldStatus: '',
      das: '',
      nState: '',
      comingStatus: {
        // orderNumber: {
        //   type: String,
        // },
        // orderItemId: {
        //   type: String,
        // },
        productInStockId: {
          type: String,
        },
        newState: {
          type: String,
        },
      },
      teamNameStatus: {
        productInStockId: {
          type: String,
        },
        newState: {
          type: String,
        },
      },
      locationStatus: {
        productInStockId: {
          type: String,
        },
        newState: {
          type: String,
        },
      },
      controlCheckStatus: {
        productInStockId: {
          type: String,
        },
        newState: {
          type: String,
        },
      },
      qualityFailed: {
        productInStockId: {
          type: String,
        },
        // orderItemId: {
        //   type: String,
        // },
        newState: {
          type: String,
        },
        description: {
          type: String,
        },
      },
      ordNum: '',
      stagesNila: [
        {
          name: this.$t('Created'),
          value: 'Created',
        },
        {
          name: this.$t('Assigned'),
          value: 'Assigned',
        },
        {
          name: this.$t('Confirmed'),
          value: 'Confirmed',
        },
        {
          name: this.$t('InPattern'),
          value: 'InPattern',
        },
        {
          name: this.$t('InSewing'),
          value: 'InSewing',
        },
        {
          name: this.$t('InHandSewing'),
          value: 'InHandSewing',
        },
        {
          name: this.$t('InControlCheck'),
          value: 'InControlCheck',
        },
        {
          name: this.$t('QualityPassed'),
          value: 'QualityPassed',
        },
        {
          name: this.$t('InStock'),
          value: 'InStock',
        },
        {
          name: this.$t('Sold'),
          value: 'Sold',
        },
        {
          name: this.$t('StockNotAvailable'),
          value: 'StockNotAvailable',
        },
        {
          name: this.$t('QualityCheckNotPassed'),
          value: 'QualityCheckNotPassed',
        },
      ],
      stages: [
        {
          name: this.$t('Created'),
          value: 'Created',
        },
        {
          name: this.$t('Assigned'),
          value: 'Assigned',
        },
        {
          name: this.$t('Confirmed'),
          value: 'Confirmed',
        },
        {
          name: this.$t('InPattern'),
          value: 'InPattern',
        },
        {
          name: this.$t('InPatternQualityPassed'),
          value: 'InPatternQualityPassed',
        },
        {
          name: this.$t('InPatternQualityNotPassed'),
          value: 'InPatternQualityNotPassed',
        },
        {
          name: this.$t('InPatternCompleted'),
          value: 'InPatternCompleted',
        },
        {
          name: this.$t('InSewing'),
          value: 'InSewing',
        },
        {
          name: this.$t('InSewingQualityPassed'),
          value: 'InSewingQualityPassed',
        },
        {
          name: this.$t('InSewingQualityNotPassed'),
          value: 'InSewingQualityNotPassed',
        },
        {
          name: this.$t('InHandSewing'),
          value: 'InHandSewing',
        },
        {
          name: this.$t('InHandSewingQualityPassed'),
          value: 'InHandSewingQualityPassed',
        },
        {
          name: this.$t('InHandSewingQualityNotPassed'),
          value: 'InHandSewingQualityNotPassed',
        },
        {
          name: this.$t('InControlCheck'),
          value: 'InControlCheck',
        },
        {
          name: this.$t('QualityPassed'),
          value: 'QualityPassed',
        },

        {
          name: this.$t('InStock'),
          value: 'InStock',
        },
        {
          name: this.$t('Sold'),
          value: 'Sold',
        },
        {
          name: this.$t('StockNotAvailable'),
          value: 'StockNotAvailable',
        },
        {
          name: this.$t('QualityCheckNotPassed'),
          value: 'QualityCheckNotPassed',
        },
        {
          name: this.$t('Recycle'),
          value: 'Recycle',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getTeamsNames', 'getTotalItemsForTeams', 'getTeamOrders', 'getArticles', 'getMaterialVariantsOfOrder', 'getLoggedInUser', 'getIsLoading', 'getProductsInStock', 'getProductInStockWithStates', 'getProductInStockMaterialVariants', 'getTeamOverview']),
  },
  watch: {
    // eslint-disable-next-line func-names
    page(value) {
      this.teamNames({
        pageNumber: value,
        pageSize: this.pageSize,
      })
      this.$router.replace({ name: 'Product Management in Store', query: { page: this.page, pageSize: this.pageSize } }).catch(() => {})
    },
    filters: {
      // eslint-disable-next-line func-names
      handler() {
        this.$router.replace({ name: 'Product Management in Store', query: { page: this.page, pageSize: this.pageSize } }).catch(() => {})
      },
      deep: true,
      immediate: true,
    },
    editEmployee(value) {
      if (value == true) {
        this.teamByTeamNameId(this.activeId)
      }
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions(['productInStock_trackAndTrace', 'locations_by_type', 'dropdown_TeamNames', 'teamNames', 'getOrderItemsManagementBy', 'getOrderById', 'updateOrderStatus', 'materialVariantsByOrderNAndArticleN', 'resetMaterialVariantsOfOrders', 'updateToQualityNotPassed', 'changeLoadingtoTrue', 'resergetOrderById', 'loadProductInStock', 'productInStock_with_current_states', 'productInStock_materialVariants', 'updateProductStatus', 'resetProductinStock', 'teamByTeamNameId', 'assignEmployeeToProductInStock']),
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)

        await this.teamNames({
          pageNumber: this.page,
          pageSize: this.pageSize,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
        if (this.getLoggedInUser.role === 'TeamLeader') {
          await this.getTeam(this.getTeamsNames[0].teamNameId);
        }
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    loadEmployees(value) {
      this.teamByTeamNameId(this.activeId)
      this.itemId = value
    },
    editProcessingUser(value) {
      const object = {
        productInStockId: value, // Added a colon after 'orderItemId'
        message: '',
        processingUserId: this.employeeId,
      };

      this.assignEmployeeToProductInStock({
        object,
        successCallback: () => {
          this.productInStock_with_current_states({
            productInStockId: this.productInStockId,
            teamNameId: this.teamNameIdddd,
          });
          this.showLocation = false;
        },
      });
    },
    assignProcessingEmployee(object) {
      this.assignEmployeeToProductInStock({
        object,
        successCallback: () => {
          this.productInStock_with_current_states({
            productInStockId: this.productInStockId,
            teamNameId: this.teamNameIdddd,
          });
          this.showLocation = false;
        },
      });
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    fetchTrackAndTrace(value) {
      this.isSidebarOpen = true
      console.log(value)
      this.productInStock_trackAndTrace({
        productInStockId: value, pageNumber: 1, pageSize: this.pageSize,
      })
    },
    getArticleAndTeamID(val1, val2) {
      this.productInStockId = val2
      this.teamNameIdddd = val1
    },
    redirectToTrace() {
      // this.$router.push({
      //   path: 'product-in-stock-track-trace',
      //   query: {
      //     teamId: this.activeId,
      //   },
      // });
    },
    async productsWithStock(val1, val2) {
      // this.changeLoadingtoTrue(false)


      if (this.activeOrderStatus === val2 && this.showO) { // Condition to skip request
        this.activeOrderStatus = ''
        this.showO = false
      } else {
        await this.productInStock_with_current_states({
          productInStockId: val2,
          teamNameId: val1,
        })
        this.activeOrderStatus = val2
        this.showO = true
      }

      this.showM = false
      // this.changeLoadingtoTrue(true)
    },


    async productInStockMaterialVariants(value) {
      // this.changeLoadingtoTrue(false)


      if (this.das === value && this.showM) { // Condition to skip request
        this.das = ''
        this.showM = false
      } else {
        await this.productInStock_materialVariants({
          articleNumber: value,
        })
        this.das = value
        this.showM = true
      }

      // this.changeLoadingtoTrue(true)
    },

    async orderItem(orderN) {
      // this.changeLoadingtoTrue(false)

      // await this.getOrderItemsManagementBy({
      //   orderNr: orderN,
      //   id: activeId,
      // });
      if (this.activeOrderStatus === orderN) {
        this.activeOrderStatus = ''
        this.showO = false
      } else {
        this.activeOrderStatus = orderN
        this.showO = true
      }
      // this.resetMaterialVariantsOfOrders();
      this.showM = false;
      // this.changeLoadingtoTrue(true)
    },

    changeS(item, oNumber, oStatus, orderItem, articleNumber) {
      console.log(item, oNumber, oStatus, orderItem, articleNumber)
      // || (oStatus == 'InSewing' && item == 'HandSewing')
      if (
        (oStatus === 'Created' && item === 'Assigned')
    || (oStatus === 'Assigned' && item === 'Confirmed')
    || (oStatus === 'Confirmed' && item === 'InPattern')
    || (oStatus === 'InPattern' && (item === 'InPatternQualityPassed' || item === 'InPatternQualityNotPassed'))
    || (oStatus === 'InPatternQualityPassed' && item === 'InPatternCompleted')
    || (oStatus === 'InPatternCompleted' && item === 'InSewing')
    || (oStatus === 'InSewing' && (item === 'InSewingQualityPassed' || item === 'InSewingQualityNotPassed'))
    || (oStatus === 'InSewingQualityPassed' && item === 'InHandSewing')
    || (oStatus === 'InHandSewing' && (item === 'InHandSewingQualityPassed' || item === 'InHandSewingQualityNotPassed'))
    || (oStatus === 'InHandSewingQualityPassed' && item === 'InControlCheck')
    || (oStatus === 'InControlCheck' && (item === 'QualityPassed' || item === 'QualityCheckNotPassed'))
    || (oStatus === 'QualityPassed' && item === 'InStock')
    || (oStatus === 'InStock' && item === 'Recycle')
    || (oStatus === 'Recycle' && item === 'InStock')
    || (oStatus === 'InStock' && item === 'Sold')
      ) {
        this.$bvModal.show('modal-change-status')
        this.articleNumberTitle = articleNumber
        if (item === 'QualityCheckNotPassed') {
          this.qualityFailed.productInStockId = oNumber
          this.qualityFailed.newState = item;
          // this.qualityFailed.orderItemId = orderItem
          this.oldStatus = oStatus
          this.changeModal = true;
          this.showLocation = false;
          this.showTeamInModal = false;
          this.showControlCheck = false
          this.nState = item
        } else if (item === 'InHandSewing') {
          this.teamNameStatus.newState = item
          this.teamNameStatus.productInStockId = oNumber
          // this.oldStatus = oStatus
          this.showTeamInModal = true;
          this.showLocation = false;
          this.changeModal = false;
          this.showControlCheck = false
          this.dropdown_TeamNames(20)
          this.teamByTeamNameId(this.activeId)
          // this.changeModal = true
          this.nState = item
        } else if (item === 'InSewing') {
          this.teamNameStatus.productInStockId = oNumber
          this.teamNameStatus.newState = item;
          this.showTeamInModal = true;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'InControlCheck') {
          this.teamNameStatus.productInStockId = oNumber;
          this.teamNameStatus.newState = item;
          this.showControlCheck = false
          this.showLocation = false;
          // this.showTeam = true;
          this.showTeamInModal = true;
          this.changeModal = false;
          this.dropdown_TeamNames(20)
          this.teamByTeamNameId(this.activeId)

          this.nState = item;
        } else if (item === 'InStock') {
          this.locationStatus.productInStockId = oNumber;
          this.locationStatus.newState = item;
          this.showLocation = true;
          this.showTeamInModal = false;
          this.changeModal = false;
          this.showControlCheck = false
          this.locations_by_type('WarehouseForProductInStock');
          this.nState = item;
        } else if (item === 'InPatternQualityNotPassed') {
          this.comingStatus.productInStockId = oNumber;
          this.comingStatus.newState = item;
          // this.locationStatus.new = item;
          this.showLocation = false;
          this.showTeamInModal = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.nState = item;
        } else if (item === 'InSewingQualityNotPassed') {
          this.comingStatus.productInStockId = oNumber;
          this.comingStatus.newState = item;
          // this.locationStatus.new = item;
          this.showLocation = false;
          this.showTeamInModal = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.nState = item;
        } else if (item === 'InHandSewingQualityNotPassed') {
          this.comingStatus.productInStockId = oNumber;
          this.comingStatus.newState = item;
          // this.locationStatus.new = item;
          this.showLocation = false;
          this.showTeamInModal = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.nState = item;
        } else {
          this.comingStatus.newState = item
          this.comingStatus.productInStockId = oNumber
          // this.comingStatus.orderNumber = oNumber
          // this.oldStatus = oStatus
          // this.comingStatus.orderItemId = orderItem
          this.showTeamInModal = false;
          this.changeModal = false
          this.showTeamInModal = false
          this.showLocation = false;
          this.showControlCheck = false
        }
      }
    },
    changeSFjolla(item, oNumber, oStatus, orderItem, articleNumber) {
      if (
        (oStatus == 'Created' && item == 'Assigned')
        || (oStatus == 'Assigned' && item == 'Confirmed')
        || (oStatus == 'Confirmed' && item == 'InPattern')
        || (oStatus == 'InPattern' && item == 'InSewing')
        || (oStatus == 'InSewing' && item == 'InHandSewing')
        || (oStatus == 'InHandSewing' && item == 'InControlCheck')
        || (oStatus == 'InControlCheck' && (item == 'QualityPassed' || item == 'QualityCheckNotPassed'))
        || (oStatus == 'QualityPassed' && item == 'InStock')
        || (oStatus == 'InStock' && item == 'Sold')
      ) {
        this.$bvModal.show('modal-change-status')
        this.articleNumberTitle = articleNumber
        if (item === 'QualityCheckNotPassed') {
          this.qualityFailed.productInStockId = oNumber
          this.qualityFailed.newState = item;
          // this.qualityFailed.orderItemId = orderItem
          this.oldStatus = oStatus
          this.changeModal = true;
          this.showLocation = false;
          this.showTeamInModal = false;
          this.showControlCheck = false
          this.nState = item
        } else if (item === 'InSewing') {
          this.comingStatus.productInStockId = oNumber
          this.comingStatus.newState = item;
          this.showTeamInModal = false;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'InHandSewing') {
          this.teamNameStatus.newState = item
          this.teamNameStatus.productInStockId = oNumber
          // this.oldStatus = oStatus
          this.showTeamInModal = true;
          this.showLocation = false;
          this.changeModal = false;
          this.showControlCheck = false
          this.dropdown_TeamNames(20)
          this.teamByTeamNameId(this.activeId)
          // this.changeModal = true
          this.nState = item
        } else if (item === 'InControlCheck') {
          this.comingStatus.productInStockId = oNumber;
          this.comingStatus.newState = item;
          this.showControlCheck = false
          this.showLocation = false;
          // this.showTeam = true;
          this.showTeamInModal = false;
          this.changeModal = false;
          this.dropdown_TeamNames(20)
          this.teamByTeamNameId(this.activeId)

          this.nState = item;
        } else if (item === 'InStock') {
          this.locationStatus.productInStockId = oNumber;
          this.locationStatus.newState = item;
          this.showLocation = true;
          this.showTeamInModal = false;
          this.changeModal = false;
          this.showControlCheck = false
          this.locations_by_type('WarehouseForProductInStock');
          this.nState = item;
        } else {
          this.comingStatus.newState = item
          this.comingStatus.productInStockId = oNumber
          // this.comingStatus.orderNumber = oNumber
          // this.oldStatus = oStatus
          // this.comingStatus.orderItemId = orderItem
          this.changeModal = false
          this.showTeamInModal = false
          this.showLocation = false;
          this.showControlCheck = false
        }
      }
    },
    async changeStage(item) {
      console.log('2344', item)
      await this.updateProductStatus({
        object: item,
        successCallback: () => {
          this.productInStock_with_current_states({
            productInStockId: this.productInStockId,
            teamNameId: this.teamNameIdddd,
          });
          this.loadProductInStock({
            assignedTeamNameId: this.teamNameIdddd,
            articleNumber: null,
            state: null,
            pageNumber: 1,
            pageSize: this.pageSize,
          })
          this.showLocation = false;
        },
      });
      if (
        (this.oldStatus == 'Created' && item.newState == 'Assigned')
        || (this.oldStatus == 'Assigned' && item.newState == 'Confirmed')
        || (this.oldStatus == 'Confirmed' && item.newState == 'InProduction')
        || (this.oldStatus == 'InProduction' && item.newState == 'InSewing')
        || (this.oldStatus == 'InSewing' && item.newState == 'InHandSewing')
        || (this.oldStatus == 'InHandSewing' && (item.newState == 'QualityPassed' || item.newState == 'QualityCheckNotPassed'))
        || (this.oldStatus == 'QualityPassed' && item.newState == 'InStock')
        || (this.oldStatus == 'InStock' && item.newState == 'Sold')
      ) {
        if (item.newState === 'QualityCheckNotPassed') {
          this.qualityFailed.description = item.message
          await this.updateToQualityNotPassed({
            object: this.qualityFailed,
            successCallback: () => {
              this.getOrderItemsManagementBy({
                orderNr: this.qualityFailed.orderNumber,
                id: this.activeId,
              });
            },
          });
        } else if (item.newState === 'InHandSewing') {
          await this.updateProductStatus({
            object: item,
            successCallback: () => {
              this.getOrderItemsManagementBy({
                orderNr: item.orderNumber,
                id: this.activeId,
              });
            },
          });
        } else {
          await this.updateProductStatus({
            object: item,
            successCallback: () => {
              this.getOrderItemsManagementBy({
                orderNr: item.orderNumber,
                id: this.activeId,
              });
            },
          });
        }
      }
    },
    async getTeam(id) {
      if (this.activeId === id) {
        this.activeId = ''
        this.showHide = false
      } else {
        this.resetProductinStock()
        this.activeId = id
        this.showHide = true
        this.loadProductInStock({
          assignedTeamNameId: id,
          articleNumber: null,
          state: null,
          pageNumber: 1,
          pageSize: this.pageSize,
        })
      }
    },


    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
  },
};
</script>

<style scoped>
.iconat{
  width:80px;
  height: 80px;
}
.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 0px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}
.main{
  margin-top: 3%;
  padding: 5px 30px 15px 115px
}
.hr{
  background-color: grey;
min-width: 201px;
height: 1px;
/* UI Properties */
border: 0.5px solid #A5A5A5;
opacity: 0.34;
}
.material-info:last-child {
  margin-right: 0 !important;
}
.material-info:last-child {
  margin-right: 0 !important;
}
.pm-li {
  cursor:pointer;
  list-style: none;
  background: #ffffff;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid #DADBDC;
  padding: 12px 10px;
  margin-top: 20px;
}
.pm-li h5 {
  margin-bottom: 0;
}
.ul-order {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  cursor:default;
}
.ul-order li {
  background: #ffffff;
  overflow-y: auto;

  border-radius: 8px;
  border: 1px solid #DADBDC;
  padding: 6px 2px 6px 10px;
  margin-top: 15px;
}
.ul-order ul {
  padding-left: 5px;
}

.order-card {
  cursor:default;
  display: flex;
}

.material-info.failed2 {
  /* background-color: rgb(209, 53, 53); */
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border: none;
  box-shadow: none;
  padding: 12px;
  color: #212529;
  border-radius: 8px;
  width: 19%;
  margin-right: 10px;
  border: 10px solid rgb(209, 53, 53);;
  /* opacity: 0.7; */
  /* color: white !important; */
}
.material-info.good {
  /* background-color: rgb(209, 53, 53); */
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border: none;
  box-shadow: none;
  padding: 12px;
  color: #212529;
  border-radius: 8px;
  width: 19%;
  margin-right: 10px;
  /* opacity: 0.7; */
  /* color: white !important; */
}
/* .material-info.failed2 p{
  background-color: rgb(209, 53, 53);
  opacity: 0.7;
  color: white !important;
} */

.order-card p {
  margin-right: 20px;
}
.stage-card {
  background: #ffffff;
  border-radius: 4px;
  /* border: 1px solid transparent; */
  padding: 10px;
  margin-bottom: 15px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  opacity: 0.3;
}
.stage-card:hover {
 background: #ffe8fc;
 /* opacity: 1 !important; */
}
.stage-card.active {
  background: none !important;
  color: #f4f1ed;
  border-color: transparent;
  opacity: 0.7;
}
.stage-card.activee {
  background-color: grey;
  opacity: 0.7;
  border-image-slice: 1 ;
  border-width: 5px ;
}
.stage-card.failed {
  background: #FF274F !important;
  opacity: 0.7;
  color: #ffffff;
  border-top:2px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #e51515  70% , #424242 );
}
.stage-card.disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}
.second-div{
    display: none;
  }

@media screen and (max-width: 1440px){
  .main{
    padding: 20px 30px 15px 85px !important;
  }
}
@media screen and (max-width: 1340px){
  .main{
    padding: 20px 15px 15px 90px !important;
  }
}
 @media screen and (max-width: 1190px) {
  .main {
    padding: 40px 0.75rem 0 0.75rem !important;
    /* justify-content: center; */
  }
 }

 @media screen and (max-width: 1200px){
  .first-div{
    display: none;

  }
  .second-div{
    display: flex;
    align-items:center;
    color:black;
  }
  .second-div p {
    font-size: 12px;
    margin-bottom:0px;
    color:black;
  }
  .main{
    padding: 80px 30px 15px 115px;
  }
 }
</style>
